import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';

import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('SplitView', {
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        var _setup$crmRef;
        return [_c('SectionSubTitle', {
          staticClass: "my-2"
        }, [_vm._v("画面種別")]), _vm._v(" "), _c('Input', {
          attrs: {
            "type": "select",
            "item-text": "label",
            "item-value": "id",
            "items": [{
              id: 'send',
              label: '送付画面'
            }, {
              id: 'complete',
              label: '完了画面'
            }],
            "clearable": false
          },
          model: {
            value: _setup.typeRef,
            callback: function callback($$v) {
              _setup.typeRef = $$v;
            },
            expression: "typeRef"
          }
        }), _vm._v(" "), _setup.typeRef == 'send' ? _c('div', {
          staticClass: "pa-2"
        }, [_c('SectionSubTitle', {
          staticClass: "my-2"
        }, [_vm._v("送付画面の設定")]), _vm._v(" "), _c('div', {
          staticClass: "d-flex"
        }, [_c('Input', {
          attrs: {
            "placeholder": "送付対象",
            "type": "select",
            "item-text": "label",
            "item-value": "id",
            "items": [{
              id: 'salesforce_contact',
              label: 'Salesforce 取引先責任者'
            }, {
              id: 'salesforce_lead',
              label: 'Salesforce リード'
            }, {
              id: 'salesforce',
              label: 'Salesforce キャンペーン'
            }, {
              id: 'hubspot_contact',
              label: 'HubSpot コンタクト'
            }, {
              id: 'hubspot',
              label: 'HubSpot キャンペーン'
            }]
          },
          model: {
            value: _setup.crmRef,
            callback: function callback($$v) {
              _setup.crmRef = $$v;
            },
            expression: "crmRef"
          }
        }), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button",
            "disabled": !_setup.crmRef || !_setup.idRef
          },
          on: {
            "click": _setup.goSend
          }
        }, [_vm._v("\n          遷移\n        ")])], 1), _vm._v(" "), _setup.crmRef ? _c('div', {
          staticClass: "d-flex"
        }, [_c('Input', {
          attrs: {
            "placeholder": (_setup$crmRef = _setup.crmRef) !== null && _setup$crmRef !== void 0 && _setup$crmRef.includes('_') ? 'CRM ID' : 'キャンペーンID',
            "clearable": ""
          },
          model: {
            value: _setup.idRef,
            callback: function callback($$v) {
              _setup.idRef = $$v;
            },
            expression: "idRef"
          }
        })], 1) : _vm._e()], 1) : _c('div', {
          staticClass: "pa-2"
        }, [_c('SectionSubTitle', {
          staticClass: "my-2"
        }, [_vm._v("完了画面の設定")]), _vm._v(" "), _c('div', {
          staticClass: "d-flex"
        }, [_c('Input', {
          attrs: {
            "placeholder": "送付種別",
            "type": "select",
            "item-text": "label",
            "item-value": "id",
            "items": [{
              id: 'touch',
              label: 'タッチ送付'
            }, {
              id: 'campaign',
              label: 'キャンペーン送付'
            }]
          },
          model: {
            value: _setup.sendTypeRef,
            callback: function callback($$v) {
              _setup.sendTypeRef = $$v;
            },
            expression: "sendTypeRef"
          }
        }), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button",
            "disabled": !_setup.sendTypeRef
          },
          on: {
            "click": _setup.goComplete
          }
        }, [_vm._v("\n          遷移\n        ")])], 1), _vm._v(" "), _setup.sendTypeRef ? [_c('div', {
          staticClass: "d-flex"
        }, [_c('Input', {
          attrs: {
            "type": "select",
            "multiple": _setup.sendTypeRef === 'campaign',
            "items": _setup.contactsRef,
            "item-text": function itemText(item) {
              return _vm.$fullName(item);
            },
            "item-id": "id",
            "item-value": "id",
            "placeholder": "コンタクト",
            "clearable": ""
          },
          model: {
            value: _setup.selectedContactIdRef,
            callback: function callback($$v) {
              _setup.selectedContactIdRef = $$v;
            },
            expression: "selectedContactIdRef"
          }
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "d-flex"
        }, [_c('Input', {
          attrs: {
            "placeholder": "LP URL",
            "clearable": ""
          },
          model: {
            value: _setup.lpUrlRef,
            callback: function callback($$v) {
              _setup.lpUrlRef = $$v;
            },
            expression: "lpUrlRef"
          }
        }), _vm._v(" "), _c('Input', {
          attrs: {
            "placeholder": _setup.sendTypeRef === 'touch' ? 'オーダーID' : 'キャンペーンID',
            "clearable": ""
          },
          model: {
            value: _setup.orderIdRef,
            callback: function callback($$v) {
              _setup.orderIdRef = $$v;
            },
            expression: "orderIdRef"
          }
        })], 1), _vm._v(" "), _c(VCheckbox, {
          attrs: {
            "label": "WOWリンクかどうか",
            "hide-details": ""
          },
          model: {
            value: _setup.isDigitalGiftRef,
            callback: function callback($$v) {
              _setup.isDigitalGiftRef = $$v;
            },
            expression: "isDigitalGiftRef"
          }
        }), _vm._v(" "), _setup.isDigitalGiftRef ? _c('div', {
          staticClass: "d-flex flex-row"
        }, [_c(VCheckbox, {
          attrs: {
            "label": "取引先責任者への連携",
            "hide-details": ""
          },
          model: {
            value: _setup.isWriteWowLinkContactRef,
            callback: function callback($$v) {
              _setup.isWriteWowLinkContactRef = $$v;
            },
            expression: "isWriteWowLinkContactRef"
          }
        }), _vm._v(" "), _c(VCheckbox, {
          staticClass: "ml-2",
          attrs: {
            "label": "リードへの連携",
            "hide-details": ""
          },
          model: {
            value: _setup.isWriteWowLinkLeadRef,
            callback: function callback($$v) {
              _setup.isWriteWowLinkLeadRef = $$v;
            },
            expression: "isWriteWowLinkLeadRef"
          }
        })], 1) : _vm._e()] : _vm._e()], 2)];
      },
      proxy: true
    }, {
      key: "second",
      fn: function fn() {
        return [_c('div', {
          staticClass: "ma-1 d-flex align-center"
        }, [_c('Input', {
          staticClass: "ma-1",
          attrs: {
            "type": "text",
            "readonly": "",
            "value": _setup.currentUrl
          }
        }), _vm._v(" "), _c('button', {
          staticClass: "tw-btn tw-btn-icon-info tw-btn-icon-small",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _setup.reload
          }
        }, [_c('mdi-reload', {
          staticClass: "tw-size-6"
        })], 1)], 1), _vm._v(" "), _c(VDivider), _vm._v(" "), _c('iframe', {
          ref: "iframeRef",
          staticStyle: {
            "width": "100%",
            "height": "100%",
            "background": "#fff",
            "border": "none"
          },
          attrs: {
            "src": "/login",
            "allow": "clipboard-read;clipboard-write"
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };